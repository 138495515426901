.line-clamped {
  --line-clamped-color: var(--theme-primary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* -webkit-line-clamp est défini par le code JS car sa valeur dépend du contexte */
}

.line-clamped:not(.opened) .line-clamp-fixed-subelement {
  display: inline;
}

.line-clamped:not(.opened)
  .line-clamp-fixed-subelement
  ~ .line-clamp-fixed-subelement::before {
  content: ' ';
  display: block;
}

.line-clamped-show-all {
  margin-top: 1em;
}

.line-clamped-show-all,
.line-clamped-show-all input[type='checkbox'] {
  display: none;
  column-gap: 0.3125rem;
  align-items: center;
}

.line-clamped-show-all label {
  font: normal normal bold 0.875rem/1.125rem var(--primary-font-family);
  color: var(--line-clamped-color);
  display: flex;
  column-gap: 0.3125rem;
  align-items: center;

  cursor: pointer;
}

.line-clamped-show-all svg {
  height: 1.25rem;
  fill: var(--line-clamped-color);
}
