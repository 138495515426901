.accueil {
  --url-bg-image: var(--url-icon-accueil);
  --alinea-left-right: 4rem;
  --content-width: 70%;
  --page-margin-horizontal: 12.5rem;

  display: flex;
  flex-direction: column;
}

@media (max-width: 1279px) {
  .accueil {
    --alinea-left-right: 3rem;
    --content-width: 100%;
    --page-margin-horizontal: 0;
  }
}

.accueil-bienvenue {
  font: normal normal normal 2.5rem/3rem var(--secondary-font-family);
  letter-spacing: 0rem;
  color: var(--theme-primary);
  padding: 1em var(--alinea-left-right);
}

@media (max-width: 1279px) {
  .accueil-bienvenue {
    font: normal normal normal 2rem/2.5rem var(--secondary-font-family);
  }
}

.accueil-edito {
  background-color: var(--theme-surface-secondary);
  color-rendering: var(--theme-on-surface-secondary);
  padding: 1em var(--alinea-left-right);
  max-width: var(--content-width);
}

.accueil-edito-title {
  font: normal normal 500 1.3rem/2rem var(--secondary-font-family);
  margin: 0 0 1rem 0;
}

.accueil-edito-content {
  font: normal normal normal 1rem var(--primary-font-family);
}
.accueil-edito-content h1 {
  font-size: 1.1rem;
  font-weight: normal;
  margin: 1rem 0 0 0;
}
.accueil-edito-content h2,
.accueil-edito-content h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 1rem 0 0 0;
}
.accueil-edito-content p {
  text-align: justify;
  margin: 0;
}

.accueil-nos-etudes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  text-decoration: none;
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  padding: 3rem;
  font: normal normal 600 1.5rem / 1.875rem var(--secondary-font-family);
  position: absolute;
  right: var(--alinea-left-right);
  top: var(--alinea-left-right);
  max-width: calc(
    100% - var(--content-width) - 2rem - var(--alinea-left-right)
  );
}

.accueil-nos-etudes svg {
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;
}

@media (max-width: 1279px) {
  .accueil-nos-etudes {
    position: initial;
    padding: 1.5rem;
    font: normal normal 600 1.25rem / 1.25rem var(--secondary-font-family);
    width: 50vw;
    align-self: flex-end;
    max-width: initial;
  }

  .accueil-nos-etudes svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media (max-width: 800px) {
  .accueil-nos-etudes {
    padding: 1.5rem;
    font: normal normal 600 1.1rem / 1.1rem var(--secondary-font-family);
    width: initial;
    align-self: stretch;
  }

  .accueil-nos-etudes svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.accueil-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;
  padding: 1em var(--alinea-left-right);
}
@media (max-width: 1800px) {
  .accueil-row {
    column-gap: 3rem;
  }
}
@media (max-width: 1440px) {
  .accueil-row {
    column-gap: 1rem;
  }
}

@media (max-width: 1279px) {
  .accueil-row {
    flex-direction: column;
    padding: 1em 0;
  }
  .accueil-row .title {
    padding: 0 var(--alinea-left-right);
  }
}

.accueil-image-du-jour {
  display: flex;
  flex-direction: column;
}

.accueil-image-du-jour img {
  width: auto;
  max-width: 65vw;
  max-height: 70vh;
  display: block;
}
@media (max-width: 1800px) {
  .accueil-image-du-jour img {
    max-width: 45vw;
    max-height: 55vh;
  }
}
@media (max-width: 1440px) {
  .accueil-image-du-jour img {
    max-width: 35vw;
    max-height: 50vh;
  }
}
@media (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 1.25),
  (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .accueil-image-du-jour img {
    max-width: 45vw;
    max-height: 55vh;
  }
}

@media (max-width: 1279px) {
  .accueil-image-du-jour .image-container {
    display: flex;
    justify-content: center;
  }
  .accueil-image-du-jour img {
    max-width: 100vw;
    max-height: 75vh;
  }
}

.accueil-image-du-jour .image-indispo svg {
  width: 25rem;
  height: 25rem;
  color: var(--theme-primary);
}

.accueil-row .title {
  font: normal normal 500 1.5rem/4rem var(--secondary-font-family);
}

.accueil-lumiere-sur {
  display: flex;
  flex-direction: column;
}

.accueil-lumiere-sur-content {
  /* stylelint indique que safari pose problème ... */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  background: linear-gradient(
    90deg,
    var(--theme-secondary) 80%,
    transparent 80%
  );
  padding: 2em;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.accueil-lumiere-sur-content-text {
  display: flex;
  flex-direction: column;
  color: var(--theme-on-secondary);
  padding-right: 0.5em;
  flex: 1;
  row-gap: 1rem;
  max-width: 35vw;
}

@media (max-width: 1440px) {
  .accueil-lumiere-sur-content-text {
    max-width: 25vw;
  }
}

@media (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 1.25),
  (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .accueil-lumiere-sur-content-text {
    max-width: 30vw;
  }
}

.accueil-lumiere-sur-content-text-title {
  font: normal normal 500 1.5rem/1.875rem var(--secondary-font-family);
}
.accueil-lumiere-sur-content-text-title a {
  display: block;
  text-decoration: none;
  color: currentColor;
}
.accueil-lumiere-sur-content-text-title a:hover,
.accueil-lumiere-sur-content-text-title a:active {
  text-decoration: underline;
}

.accueil-lumiere-sur-localisation {
  font: normal normal 600 1.25rem/1.375rem var(--secondary-font-family);
}
.accueil-lumiere-sur-localisation a {
  color: currentColor;
  text-decoration: none;
}
.accueil-lumiere-sur-localisation a:hover,
.accueil-lumiere-sur-localisation a:active {
  text-decoration: underline;
}
.accueil-lumiere-sur-texte-libre h1 {
  font-size: 1.2rem;
  line-height: 1.375rem;
  font-weight: 600;
}
.accueil-lumiere-sur-texte-libre h2 {
  font-size: 1.1rem;
  line-height: 1.275rem;
  font-weight: 600;
}
.accueil-lumiere-sur-texte-libre h3 {
  font-size: 1rem;
  line-height: 1.175rem;
  font-weight: 500;
}

.accueil-lumiere-sur-lien {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.accueil-lumiere-sur-lien a {
  font: normal normal 700 1rem var(--primary-font-family);
  padding: 1em 2em;
  text-decoration: none;
  background-color: var(--theme-on-primary);
  color: var(--theme-primary);
  display: flex;
  align-items: center;
}

.accueil-lumiere-sur-lien a .icon-lien-dossier {
  margin-left: 0.5em;
}

.accueil-lumiere-sur-lien a:hover {
  background-color: var(--theme-primary);
  color: var(--theme-on-primary);
}

.accueil-lumiere-sur-lien svg {
  width: 2rem;
  margin-left: 1em;
}

.accueil-lumiere-sur-content-image {
  align-self: center;
  flex: 0;
}
.accueil-lumiere-sur-content-image .image-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.accueil-lumiere-sur-content-image img {
  display: block;
  object-fit: contain;
  width: auto;
  max-width: 25vw;
  max-height: 35vh;
  box-shadow: 1.25rem 1.25rem 0rem var(--theme-primary);
}
@media (max-width: 1800px) {
  .accueil-lumiere-sur-content-image img {
    max-width: 20vw;
    max-height: 25vh;
  }
}
@media (max-width: 1440px) {
  .accueil-lumiere-sur-content-image img {
    max-width: 15vw;
    max-height: 20vh;
  }
}
@media (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 1.25),
  (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .accueil-lumiere-sur-content-image img {
    max-width: 25vw;
    max-height: 35vh;
  }
}

@media (max-width: 1279px) {
  .accueil-lumiere-sur-content {
    flex-direction: column;
    padding: 0 2rem;
    background: none;
    background-color: var(--theme-secondary);
  }
  .accueil-lumiere-sur-content .accueil-lumiere-sur-content-image {
    order: 1;
  }
  .accueil-lumiere-sur-content .accueil-lumiere-sur-content-text {
    order: 2;
    max-width: initial;
  }
  .accueil-lumiere-sur-content-image img {
    max-width: 100vw;
    max-height: 75vh;
    box-shadow: none;
  }

  .accueil-lumiere-sur-lien {
    margin: 0 0 1rem 0;
  }
}

.accueil-lumiere-sur-content-image .image-indispo svg {
  width: 19rem;
  height: 19rem;
  color: var(--theme-secondary);
  background-color: var(--theme-on-secondary);
  box-shadow: 1.25rem 1.25rem 0rem var(--theme-primary);
  padding: 2rem;
}

.accueil .dossiers-carousel {
  margin: 3rem var(--page-margin-horizontal);
}

@media (max-width: 1279px) {
  .accueil .dossiers-carousel {
    margin: 3rem 0;
  }
}
